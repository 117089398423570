import { Pipe, PipeTransform } from '@angular/core';
import { getPlainText } from '@shared/utilities/string.utilities';

@Pipe({
  name: 'plainText',
})
export class PlainTextPipe implements PipeTransform {
  transform(html: string | null): any {
    return getPlainText(html);
  }
}
